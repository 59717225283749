import React from 'react';
import Icon from '../Icon';
import { IconWhatsapp } from '../Icon/IconSet';
import Constants from '../../common/Constants';

import styles from './WhatsappButton.module.scss';

const WhatsappButton = () => {
    return (
        <div className={styles['container']}>
            <a href={Constants.WhatsappLink} target='_blank' rel='noopener noreferrer'>
                <div className={styles['icon']}>
                    <Icon icon={IconWhatsapp} customSize />
                </div>
            </a>
        </div>
    )
}

export default WhatsappButton;
